@menu_bg: rgba(48, 48, 52) ;

.layout{
  header{
    height: 50px;
    background-color: #fff !important;
    border-bottom: 1px solid #f0f0f0;
  }

  aside, .ant-menu{
    background-color: @menu_bg !important;
  }

  .ant-layout-sider-trigger{
    background-color: #202020 !important;
  }
  .ant-menu{
    height: calc(100% - 120px);
    overflow-y: auto;
    .menu-1{
      border-radius: 0;
      text-align: left;
    }
    .menu-2{

      margin-bottom: 1px;
      border-radius: 0;
      text-align: left;
    }
    .ant-menu-item,.ant-menu-submenu{
      background-color: @menu_bg;
    }
    .ant-menu-item-selected{
      background-color: #666 !important;
    }
  }
  .logo{
    height: 50px;
  }

  // 头像
  .avatar{
    padding: 2px 10px;
    color: #fff;
    span{
      background-color: #909090;
    }

    .avatar-name{
      width: 120px;
      text-align: center;
      .name a{
        font-size: 16px;
        color: #fff;
      }
      .scope{
        display: inline-block;
        font-size: 12px;
        background-color: #444;
        padding: 4px 12px;
      }
      .logout-btn{
        span {
          margin-right: 8px;
          background: inherit;
          font-size: 14px;
        }
        margin:6px auto 0;
        font-size: 12px;
        padding: 4px 12px;
        width: 90px;
        cursor: pointer;

      }
      .logout-btn:hover{
        color: #ccc;
      }
    }

  }
}
