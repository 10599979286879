
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#666;
  font-size: 12px;
  //padding: 2px 4px;
  //overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-header{
  font-size: 14px;
  padding: 4px;
}

.hidden{
  display: none;
}

.ant-form-item {
  margin-bottom: 4px;

  .ant-form-item-control-input {
    min-height: 40px;
  }

  label {
    height: 24px !important;
  }
}
.ant-tabs{
  .ant-tabs-nav{
    margin-bottom: 2px;
  }
}
.ldh-page{
  overflow-y: auto;
}
.App{
  min-width: 1200px;
}
#root ,.App,.App>section, .ldh-page, .ant-tabs-tabpane{
  height: 100%;
}
.app-index{
  height: 100%;
  display: flex;
  flex-direction: column;
  .app-tabs{
    flex:1;
    overflow: auto;
    .ant-tabs-content{
      height: 100%;
    }
  }
  .ant-table-wrapper{
    .ant-spin-nested-loading, .ant-spin-container, .ant-table, .ant-table-container{
      height: 100%;
    }
    .ant-table-body{
      height: calc(100% - 30px);
    }
  }
}

.footer {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  width: 600px;
  margin: auto;
  a{
    margin-left: 40px;
    line-height: 20px;
    color: #999;
  }
}


