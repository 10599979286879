
.width(@size) { width: @size; }
.minWidth(@size) { min-width: @size; }
.maxWidth(@size) { max-width: @size; }

.height(@size) { height: @size; }
.minHeight(@size) { min-height: @size; }
.maxHeight(@size) { max-height: @size; }

.w-auto { width: auto !important;  }
.w-full { .width(100%) !important; }
.w-half { .width(50%) !important; }

.w-10 { .width(10px) !important; }
.w-15 { .width(15px) !important; }
.w-20 { .width(20px) !important; }
.w-24 { .width(24px) !important; }
.w-25 { .width(25px) !important; }
.w-35 { .width(35px) !important; }
.w-40 { .width(40px) !important; }
.w-45 { .width(45px) !important; }
.w-47 { .width(47px) !important; }
.w-48 { .width(48px) !important; }
.w-50 { .width(50px) !important; }
.w-60 { .width(60px) !important; }
.w-65 { .width(65px) !important; }
.w-70 { .width(70px) !important; }
.w-80 { .width(80px) !important; }
.w-85 { .width(85px) !important; }
.w-90 { .width(90px) !important; }
.w-100 { .width(100px) !important; }
.w-114 { .width(114px) !important; }
.w-120 {.width(120px) !important; }
.w-130 { .width(130px) !important; }
.w-134 { .width(134px) !important; }
.w-140 { .width(140px) !important; }
.w-150 { .width(150px) !important; }
.w-170 { .width(170px) !important; }
.w-200 { .width(200px) !important; }
.w-300 { .width(300px) !important; }

.h-20{.height(20px) !important; }
.h-30{.height(30px) !important; }
.h-40{.height(40px) !important; }



.mt(@size) { margin-top: @size !important; }
.mr(@size) { margin-right: @size !important; }
.mb(@size) { margin-bottom: @size !important; }
.ml(@size) { margin-left: @size !important; }

.mt-10{ .mt(10px)}
.mt-20{ .mt(20px)}
.mt-40{ .mt(40px)}

.mr-0 { .mr(0) }
.mr-5 { .mr(5px) }
.mr-8 { .mr(8px) }
.mr-12 { .mr(12px) }
.mr-20 { .mr(20px) }
.mr-30 { .mr(30px) }

.mb-0 { .mb(0) }
.mb-5 { .mb(5px) }
.mb-8 { .mb(8px) }
.mb-10 { .mb(10px) }

.ml-8{ .ml(8px)}

.fs-12{font-size: 12px}
