.mod-error-page {
    width: 500px;
    margin: 100px auto 0;
    text-align: center;
    line-height: 2;
    color: #7b7e8a;
    .status {
        font-size: 70px;
        line-height: 1;
    }
    .btn-back {
        cursor: pointer;
        font-size: 18px;
        background: #63a4fe;
        padding: 5px 60px;
        color: #fff;
        border: 0 none;
        border-radius: 30px;
        margin-top: 100px;
        &:focus,
        &:active {
            border: 0;
        }
    }
}

