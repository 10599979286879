@text:#101010;
@num:#0E8EEE;
@primary: #409EFF;
@warn: #F08622;
@danger: #EB4B4B;

@btn_primary: rgb(48,48,52);
@btn_primary_hover: rgba(48,48,52,.8);
@th_bg:rgb(246,247,251);
@td_bg:rgb(252,252,252);
@td_padding: 6px;
body {
  padding: 0;
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif ,Arial, Verdana, SimSun;
  overflow-y: hidden;
}


.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.text-right{text-align: right}
.text-left{text-align: left}
.text-center{text-align: center !important;}




.ant-select-selection-item{
  text-align: left;
}
.ant-form-item-explain-error{
  text-align: left;
}

.ldh-page{
  padding: 10px 0 35px;
  background-color: #F6F7FB;
  display: flex;
  flex-direction: column;
  .page-title{
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    padding:0 15px;
    line-height: 49px;
    border-bottom: 1px solid #f0f0f0;
    color: #333;
    background-color: #fff;
  }
  .status-bar{
    text-align: left;
    padding: 10px 0 20px;
    div{
      display: inline-block;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      border-right: 2px solid #eee;
      padding: 0 20px;
      &:hover, &.active{
        color: #0E8EEE;
      }
    }
    div:last-of-type{
      border-right: none;
    }
  }
  .page-search{
    padding: 15px;
    margin-bottom: 10px;
    background-color: #fff;

    .ant-select{
      min-width:180px;
    }
    .ant-picker-range{
      min-width:180px;
    }
    .page-search-col{
      align-items: end;
      padding-bottom: 10px;
    }
    .ant-form-item{
      margin-bottom: 10px;
    }
  }



  .page-body{
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    padding: 12px;
    .table-content{
      flex: 1;
      overflow-y: auto;
    }
    background-color: #fff;
    th, td{
      text-align: center !important;
      div{
        text-align: left;
      }
    }
    td:last-of-type{
      div{
        text-align: center;
      }
    }
    th{
      background-color: @th_bg !important;
      border-radius: @borderRadius !important;
    }
    td{
      background-color: @td_bg !important;
      padding: 8px 2px;
    }
    &.detail{
      padding: 12px 30px;

      .set-body{
        width: 750px;
        min-width: 750px;
      }

      .form-item-tip{
        padding-left: 180px;
        text-align: left;
        margin-top: -10px;
        color: #c0c0c0;
        font-size: 12px;

      }


    }
  }
  .page-control{
    padding: 5px 10px 20px;
  }
  .page-field-set {
    .set-title{
      font-size: 20px;
      font-weight: bold;
      padding: 10px;
      border-bottom: 1px solid #f0f0f0;
    }
    .sub-title{
      margin-left: 20px;
      color: #a0a0a0;
      font-size: 14px;
    }
    .set-body{
      padding-top: 20px;
    }
    .ant-form-item{
      margin-bottom: 14px;
    }

  }
}

@height: 40px;
@borderRadius:4px;
.ant-form-item-label{
  line-height: @height;
}
// input
.ant-input-affix-wrapper, .ant-btn, .ant-input-number,.ant-picker-range{
  height: @height;
  border-radius: @borderRadius;
}
.ant-form-item-control-input{
  min-height: @height;
  border-radius: @borderRadius;
}
button:not(.ant-switch), .ant-table-wrapper table, .ant-pagination-item,.ant-select-selector, .ant-select-item,
.ant-select-dropdown, .ant-modal-content{
  border-radius: @borderRadius !important;
}
// select
.ant-select:not(.ant-pagination-options-size-changer, .ant-select-multiple){
  .ant-select-selector{
    height: @height;
    input{
      height: @height !important;
    }
    .ant-select-selection-item{
      line-height: @height !important;
      height: @height !important;
    }
  }
}

.ant-select-multiple{
  .ant-select-selector{
    height: @height;
    input{
      height: @height !important;
    }
    .ant-select-selection-overflow{
      margin-top: -2px;
    }
    .ant-select-selection-item{
      line-height: 24px !important;
      height: 24px !important;
      border: 1px solid #e0e0e0;
    }
  }
}
.ant-form-item-control-input-content{
  text-align: left;
}
.ant-select-dropdown{
  border: 1px solid #d9d9d9;
  padding: 0;
  .ant-select-item-option{
    line-height: @height !important;
    height: @height !important;
    padding: 0 10px;
    &:not(:last-of-type){
      border-bottom: 1px solid #d9d9d9;
    }

  }
}

// button
.ant-btn:not(.ant-btn-link){
  padding: 0 30px;

  &.ant-btn-primary{
    background-color: @btn_primary;
    border:  @btn_primary;;
    color: #fff;
    &:hover{
      background-color: @btn_primary_hover;
      border:  @btn_primary_hover;;
    }
  }
  &.ant-btn-default{
    border: 1px solid @btn_primary;
    color: @btn_primary;
    &:hover{
      background-color: @btn_primary;
      color: #fff;
    }
  }

}
// modal
.ant-modal-content{
  border-radius: @borderRadius;
}

th{
  padding: 12px 8px !important;
}
tr:not(.ant-table-measure-row) td{
  padding: 6px 8px !important;
}

//分页条
.ant-pagination{
  .ant-pagination-total-text{
    margin-right: 20px;
  }
  li:not(.ant-pagination-disabled){
    a{
      color: #333;
    }
  }
  li:not(.ant-pagination-total-text, .ant-pagination-options){
    border: 1px solid #f0f0f0;
    margin: 0;
    border-radius: @borderRadius !important;
    padding: 0px 10px;


    &.ant-pagination-item-active{
      border:1px solid @btn_primary !important;
      background-color: @btn_primary;
      a{
        color: #fff;
      }

      &:hover{
        background-color: @btn_primary_hover !important;
        border:1px solid @btn_primary !important;
      }
    }
  }
}
.ant-radio-checked .ant-radio-inner{
  background-color: @btn_primary !important ;
  border-color: @btn_primary !important ;
}


.ant-layout .ant-layout-sider-trigger {
  position: absolute;
}


// modal
.ant-modal{
  .ant-modal-content{
    padding: 0;
  }
  .ant-modal-header{
    padding: 8px 16px;
    border-radius: 0;
    background-color: @btn_primary;
  }
  .ant-modal-body{
    padding: 8px 16px;
    min-height: 100px;
  }
  .ant-modal-footer{
    padding: 16px 16px;
    margin-top: 10px;
    border-top: 1px solid #f0f0f0;
  }
  .ant-modal-title{
    color: #fff;
  }
  .ant-modal-close{
    top:10px;
    color:#fff
  }
  .ant-btn{
    height: 30px;
    padding: 0 12px;
  }
  .form-item-tip{
    color:#c0c0c0;
    font-size: 12px;
    margin-left: 100px;
    margin-top: -15px;
    margin-bottom: 15px;

    &.form-tip{
      color: #ff2200;
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 14px;
      svg{
        margin-right: 4px;
      }
    }
  }

  .ant-form-item{
    margin-bottom: 15px;
  }
}
.page-404{
  font-size: 32px;
  color: #c0c0c0;
}
a.danger{
  color: @danger;
  &:hover{
    color: #FB9B9B;;
  }
}

.ant-tooltip{
  max-width: 800px;
}
.tip-img{
  .ant-tooltip-content{
    padding: 5px;
    width: 450px;
    img{
      width: 425px;
    }
  }
}

.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-title {
  padding: 12px 0;
}
